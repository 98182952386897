import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/land/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id",
    name: "Home",
    component: Home,
    meta: {
      title: "",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.path == "/user.html") {
    next();
  } else {
    window.location.href = "https://qzone.qq.com/404.html";
  }
});

export default router;
