import axios from "axios";

const sericve = axios.create({
  baseURL: "https://d29v1z6gv4u2ag.cloudfront.net",
  // baseURL: "/",
  timeout: 15000,
});

sericve.interceptors.request.use(
  function(config) {
    return config;
  },

  function(error) {
    return Promise.reject(error);
  }
);

sericve.interceptors.response.use(
  function(response) {
    // 状态码200
    if (response.data.code === 200 || response.status === 200) {
      return response;
    } else {
      this.$message.success({
        type: "error",
        message: "服务器错误",
      });
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default sericve;
