<template>
  <div class="mobilePage">
    <div class="main">
      <div class="header">
        <div class="logo"></div>
        <div class="contactOfficialBox">
          <div class="webIcon" @click="toWeb"></div>
          <div class="contactOfficial" @click="toTg"></div>
        </div>
      </div>
    </div>
    <div class="bottomBox">
      <div
        class="downloadBox"
        v-if="os.isPhone"
        @click="getApkInfo('ios')"
      ></div>
      <div class="downloadBox" v-else @click="getApkInfo('android')"></div>
      <div :class="os.isPhone ? 'safariTip' : 'bottomTip'"></div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>

    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      let url = window.location.href;
      this.$copyText(url).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toWeb() {
      window.open("https://d21s782qcpmosd.cloudfront.net");
    },
    toTg() {
      window.open("https://t.me/haijiaoshequ99");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  overflow-y: auto;
  .main {
    height: 772px;
    width: 375px;
    background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
    background-size: 375px 812px;
    background-position: 0 -40px;
    .header {
      height: 64px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgb(0, 0, 0, 0.5);
      padding: 0 30px 0 18px;
      .logo {
        height: 44px;
        width: 129px;
        background: url("./../../../assets/images/mobile1/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .contactOfficialBox {
        display: flex;
        align-items: center;
        .webIcon {
          height: 26px;
          width: 60px;
          background: url("./../../../assets/images/mobile1/webIcon.png")
            no-repeat;
          background-size: 100% 100%;
          margin-right: 9px;
        }
        .contactOfficial {
          height: 26px;
          width: 60px;
          background: url("./../../../assets/images/mobile1/contactOfficialBg.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .bottomBox {
    height: 110px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .bottomTip {
      height: 38px;
      width: 100%;
      background: url("./../../../assets/images/mobile1/bottomTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .safariTip {
      height: 38px;
      width: 100%;
      background: url("./../../../assets/images/mobile1/safariTip.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .downloadBox {
      height: 44px;
      width: 164px;
      background: url("./../../../assets/images/mobile1/downloadBtn.png")
        no-repeat;
      background-size: 100% 100%;
      margin: 7px auto;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
